import { ethers } from "ethers"
import AbiPaymentRouter from "../web3/abi/PaymentRouterContract.json"
import AbiOfferedTokens from "../web3/abi/OfferedTokensContract.json"

const paymentContractAddress = `${process.env.REACT_APP_CONTRACT_PAYMENT}`
const offeredTokensAddress = `${process.env.REACT_APP_CONTRACT_OFFER}`

const erc20ABI = [
  "function balanceOf(address account) view returns (uint256)",
  "function approve(address spender, uint256 amount) external returns (bool)",
  "function symbol() view returns (string)"
]

let provider = window.ethereum || null
let selectedAddress = null
let ethersProvider = null
let signer = null
let paymentControllerContract = null
let supportedCurrenciesContract = null

export function init(account) {
  if (!provider) throw new Error("No metamask")
  ethersProvider = new ethers.providers.Web3Provider(provider)
  signer = ethersProvider.getSigner()
  selectedAddress = account
  paymentControllerContract = new ethers.Contract(paymentContractAddress, AbiPaymentRouter.abi, signer)
  supportedCurrenciesContract = new ethers.Contract(offeredTokensAddress, AbiOfferedTokens.abi, signer)
}

export async function getBalanceOf(address = selectedAddress) {
  const balance = await ethersProvider?.getBalance(address)
  return balance
}

export async function getERC20BalanceOf(tokenAddress, address = selectedAddress) {
  const erc20Contract = new ethers.Contract(tokenAddress, erc20ABI, ethersProvider)
  const ercBalance = await erc20Contract.balanceOf(address)
  return ercBalance
}

export async function approve(spendingTokenAddress, amountInWithSlippage_) {
  const amountInWithSlippage = ethers.utils.parseUnits(amountInWithSlippage_.toString(), 18)
  const spendingTokenInstance = new ethers.Contract(spendingTokenAddress, erc20ABI, signer)
  const approve = await spendingTokenInstance.approve(paymentContractAddress, amountInWithSlippage)
  await approve.wait()
}

/// Main public method for executing on-chain payments
/// @param merchantId_ The merchant ID
/// @param merchantPaymentId_ The merchant payment ID
/// @param merchantTerminalId_ The merchant Terminal ID (optional)
/// @param paymentData_ The payment data (optional)
/// @param paymentAmount_ The payment amount in Merchant base currency
/// @param offeredToken_ The offered token address (Token in which user want to make payment) - if zero address then protocol token is used
/// @param offeredTokenAmountInMax_ The MAX offered token amount in which user want to make payment (slippage control)
/// @param swapPath_ The swap path for converting offered token to protocol token
export async function processPayment(merchantId, merchantPaymentId, merchantTerminalId, paymentData, paymentAmount, userTokenInstanceAddress, offeredTokenAmountInMax, uniswapPath) {
  let txBlockNumber = null
  let eventResult = ""

  try {
    const estimatedGas = await paymentControllerContract.estimateGas.processPayment(
      merchantId,
      merchantPaymentId,
      merchantTerminalId,
      paymentData,
      paymentAmount,
      userTokenInstanceAddress,
      offeredTokenAmountInMax,
      uniswapPath
    )

    const tx = await paymentControllerContract.processPayment(
      merchantId,
      merchantPaymentId,
      merchantTerminalId,
      paymentData,
      paymentAmount,
      userTokenInstanceAddress,
      offeredTokenAmountInMax,
      uniswapPath,
      { gasLimit: estimatedGas }
    )

    await tx.wait().then((receipt) => {
      console.log('processPayment->receipt', receipt)
      txBlockNumber = receipt.blockNumber
      const _success = receipt.events.find(event => event.event === 'ProcessPaymentSuccess')
      const _partSuccess = receipt.events.find(event => event.event === 'ProcessPaymentPartialSuccess')
      const _fail = receipt.events.find(event => event.event === 'ProcessPaymentFailure')
      if (_success) {
        eventResult = "success"
      } else if (_partSuccess) {
        eventResult = "partial_success"
      } else if (_fail) {
        eventResult = "fail"
      }
    })
    return { provider: ethersProvider, blockNumber: txBlockNumber, eventResult }
  } catch (error) {
    console.log('processPayment->error', error)
    throw new Error(error)
  }
}

export async function getSymbol(tokenAddress) {
  const erc20Contract = new ethers.Contract(tokenAddress, erc20ABI, signer)
  const symbol = await erc20Contract.symbol()
  return symbol
}

export async function getOfferTokenData(merchantId, paymentAmount, offeredToken, address) {
  const data = await supportedCurrenciesContract.getOfferTokenData(merchantId, ethers.utils.parseEther(paymentAmount.toString()), offeredToken, address)
  return data
}
