import React from 'react'
import LetterIcon from '../assets/icons/letter.svg'


export default function ContactInformation(props) {
  const [emailAddress, setEmailAddress] = React.useState({ value: '', error: true })
  const [givenConsent, setGivenConsent] = React.useState(false)

  const handleEmailChange = (e) => {
    const isValid = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(e)
    setEmailAddress({ value: e, error: !isValid })
  }

  return (
    <div>
      <div className="flex justify-between items-center border-b border-borderGray w-full pb-5">
        <h4 className='font-xl leading-[18px] font-medium'>Contact information</h4>
        <img src={LetterIcon} alt="Letter icon" />
      </div>

      <p className='font-[14px] leading-[18px] mt-4 mb-8'>Please provide your email to receive payment notifications.</p>

      <label className="text-gray text-sm">Email address</label>
      <input className="w-full border border-borderGray rounded-[4px] px-3 py-2 mt-1 mb-12" type="text" onChange={(e) => handleEmailChange(e.target.value)} />

      <div className="flex items-start">
        <input type="checkbox" className="mr-3 w-[14px] h-[14px] mt-1" value={givenConsent} onChange={() => setGivenConsent(!givenConsent)} />
        <p>By giving my email address, I give explicit consent to GoCrypto to use it to contact me about payment issues. <span className='text-blueGreen'>View Privacy Policy</span></p>
      </div>

      <button className='yellow-button mt-[73px] font-medium disabled:bg-[#D2DFE0]' onClick={() => props.onSubmit()} disabled={emailAddress.error || !givenConsent}>Submit</button>

      <button className='yellow-button mt-6 font-medium' onClick={() => props.onSkip()}>Skip</button>
    </div>
  )
}
