import React, { useRef } from "react"
import UsdtIcon from "../assets/images/crypto/usdt.png"
import GocIcon from "../assets/images/crypto/goc.png"
import { utils } from "ethers"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { useMetaMask } from "metamask-react"
import { getERC20BalanceOf, getOfferTokenData } from "../helpers/metamask"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import { supportedCurrencies } from "../helpers/currencies"


export default function CurrencyOptions(props) {
  const shouldFetch = useRef(true)
  const { account } = useMetaMask()
  const [currencyOptions, setCurrencyOptions] = useState(null)
  const [preFilteredOptions, setPreFilteredOptions] = useState([])

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false
      updateUserBalances()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getAllowedCurrencies() {
    // TODO - get supported currencies from chain
    // const response = await axios.get(`${config.baseUrl}/api/currencies`)
    // return response.data
    return supportedCurrencies
  }

  async function updateUserBalances() {

    let allowedCurrencies = await getAllowedCurrencies()
    let result = []

    for (const c of allowedCurrencies) {
      try {
        const tokenData = await getTokenData(c.address)
        const tokenBalanceHex = await getERC20BalanceOf(c.address, account)
        const tokenBalance = utils.formatUnits(tokenBalanceHex, tokenData?.offeredTokenDecimals.toString())
        const amount = utils.formatUnits(tokenData?.offeredTokenAmount.toString(), tokenData?.offeredTokenDecimals.toString())
        if (tokenData?.active) {
          result.push({
            ...c,
            name: c.name,
            price: amount,
            balance: tokenBalance,
            icon: c.name === "GOC" ? GocIcon : UsdtIcon,
            currency: c.name,
            liveAmount: amount,
            swapPath: c.swapPath ?? "",
            decimals: tokenData?.offeredTokenDecimals.toString()
          })
        }
      } catch (error) {
        // console.log(error)         
      }
    }

    setPreFilteredOptions(result)
    setCurrencyOptions(result)
  }

  async function getTokenData(address) {
    const result = await getOfferTokenData(
      props.merchantId,
      props.totalAmount,
      address,
      account
    )
    return result
  }

  function filterCurrencyOptions(filterTerm) {
    if (!filterTerm) {
      setCurrencyOptions(preFilteredOptions)
    } else {
      const filteredCurrencyOptions = currencyOptions.filter((currencyOption) =>
        currencyOption.name.toLowerCase().includes(filterTerm.toLowerCase())
      )
      setCurrencyOptions(filteredCurrencyOptions)
    }
  }

  function handleOptionClick(option) {
    const balance = parseFloat(option.balance)
    if (option.price > balance || option.price === 0) {
      toast.info('Not enough balance!')
      return
    }
    props.onCurrencyOptionSelection(option)
  }

  return (
    <section>
      <h4 className="mb-6 flex cursor-pointer items-center text-lg font-medium" onClick={() => props.goBack()}>
        <ArrowLeftIcon className="mr-4 h-[18px] w-[18px]" />
        Choose Currency
      </h4>
      <input
        type="search"
        placeholder="Search wallet or currency"
        className="search-field payment-card-shadow mb-6 w-full border border-lightBlueBorder"
        onChange={(e) => filterCurrencyOptions(e.target.value)}
      />

      {currencyOptions
        ? (
          currencyOptions.map((c, i) => (
            <div key={i}>
              <div
                onClick={() => handleOptionClick(c)}
                className={`payment-card-shadow mb-4 flex w-full cursor-pointer items-center justify-between rounded-lg border border-lightBlueBorder py-5 px-3 
                    ${Number(c?.price) > Number(c?.balance) ? "disabled pointer-events-none grayscale-[100%]" : ""}`}
              >
                <div className="flex w-full items-center justify-between">
                  <img src={c.icon} alt="icon" className="mr-4 h-8 w-8" />
                  <div className="w-full pr-4">
                    <div className="flex w-full items-center justify-between">
                      <p>{c?.name}</p>
                      {c.price ? (
                        <p className="text-sm">{parseFloat(c?.price).toFixed(2) + " " + c.name}</p>
                      ) : (
                        <p className="text-sm">Loading...</p>
                      )}
                    </div>
                    <div className="flex w-full items-center justify-between">
                      <p className="text-gray">Balance</p>
                      <p className="text-sm text-gray">{parseFloat(c.balance).toFixed(2) + " " + c.currency}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-[18px] w-[18px]" />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex w-full justify-center pt-10">
            <div className="loader"></div>
          </div>
        )}
    </section>
  )
}
