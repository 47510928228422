import React from 'react'
import MetamaskIcon from '../assets/images/metamask.png'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { getTargetNetwork } from '../helpers/networks'

const basePaymentOptions = [
    { name: 'Metamask', text: getTargetNetwork().name, fee: '0.01', icon: MetamaskIcon }
]

export default function PaymentOptions(props) {
    const [paymentOptions, setPaymentOptions] = React.useState(basePaymentOptions)

    function filterPaymentOptions(filterTerm) {
        if (!filterTerm) {
            setPaymentOptions(basePaymentOptions)
        }
        else {
            const filteredPaymentOptions = paymentOptions.filter((paymentOption) => paymentOption.name.toLowerCase().includes(filterTerm.toLowerCase()))
            setPaymentOptions(filteredPaymentOptions)
        }
    }

    return (
        <section>
            <h4 className='mb-6 font-medium text-lg'>Payment options</h4>
            <input type="search" placeholder='Search wallet or currency' className='search-field w-full mb-6 payment-card-shadow border border-lightBlueBorder' onChange={(e) => filterPaymentOptions(e.target.value)} />

            {paymentOptions.map((paymentOption, index) => (
                <div className="flex justify-between items-center border border-lightBlueBorder
                 rounded-lg w-full py-5 px-3 payment-card-shadow mb-4 cursor-pointer"
                    onClick={() => props.onPaymentOptionSelection(paymentOption)}
                    key={index}>
                    <div className="flex justify-between items-center">
                        <img src={paymentOption.icon} alt="Metamask icon" className='mr-4' />
                        <div>
                            <h5 className='font-medium'>{paymentOption.name}</h5>
                            <p className='text-gray text-sm mb-1'>{paymentOption.text}</p>
                            <p className='bg-veryLightBlue text-blueGreen text-[12px] p-1 rounded-[10px] w-max'>{`Gas fee: ${paymentOption.fee} ${getTargetNetwork().symbol}`}</p>
                        </div>
                    </div>
                    <ChevronRightIcon className='h-[18px] w-[18px]' />
                </div>
            ))}
        </section>
    )
}
